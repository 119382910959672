import { css } from '@emotion/react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { createIPadCss } from '~/css/createIPadCss'
import { createMobileCss2 } from '~/css/createMobileCss'
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { Sgx_SidePane1 } from './sgx_SidePane1'
import { Sgx_Topbar } from './sgx_Topbar'
import { Sgx_SidePane2 } from './sgx_SidePane2'
import { Sgx_Footer } from './sgx_Footer'
import { Sgx_DataCard } from './sgx_DataCard'
import { sgx_TopInfo } from './sgx_TopInfo'

export const sgx_init = (templateProps: TemplateProps) => {
  //sgx_initStyling(templateProps)
  //sgx_initStrategies()

  useThemeStore.setState({ theme: 'light' })
  store.charting.setThemeMode(useThemeStore.getState().theme)

  templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web')

  store.charting.widgetOptions = {
    ...store.charting.widgetOptions,
    symbol: 'CN-1',
    interval: '5',
    enableVolumeIndicator: true,
    disabledHeaderWidget: false,
    disabledLeftToolbar: false,
    disabledTimeframesToolbar: true,
    disabledHeaderChartType: true,
    disabledHeaderCompare: true,
    disabledGoToDate: true,
    disabledHeaderSaveload: true,
    overrides: {
      ...store.charting.darkOverrides,
    },
  }

  templateProps.layout.cssset = css`
    grid-template-rows: 66px 64px 500px calc(100vh - 300px) 256px;
    grid-template-columns: 336px 1fr 336px;
    place-content: flex-start;
    font-family: Lato, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
    background-color: #ffffff;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Row3 Row3 Row3'
      'Drawer1 Chart Drawer2'
      'Drawer1 Row2 Drawer2'
      'Row4 Row4 Row4';

    ${createIPadCss(css`
      grid-template-rows: 48px 64px calc(100vh - 112px) 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css`
      grid-template-rows: 48px 64px calc(100vh - 112px) 256px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `

  templateProps.layout.Row1 = Sgx_Topbar
  templateProps.layout.Drawer1 = Sgx_SidePane1
  templateProps.layout.Drawer2 = Sgx_SidePane2
  templateProps.layout.Row2 = Sgx_DataCard
  templateProps.layout.Row3 = sgx_TopInfo
  templateProps.layout.Row4 = Sgx_Footer
  //templateProps.layout.login = Sgx_LoginView
}
