import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const Sgx_Topbar = memo<ReactProps>(function Sgx_Topbar() {
  return (
    <styleds.container>
      <img
        css={css`
          width: 132px;
          height: 48px;
        `}
        src='sgx/sgx.svg'
      />
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    position: fixed;
    ${fill_horizontal_cross_center};
    height: 66px;
    z-index: 1000;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid #222222;
    padding: 0 16px;
  `,
}
