import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { proxy, useSnapshot } from 'valtio'
import { apirc } from '~/configs/apirc'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import {
  useIntraday,
  useLatestTradeDateSize,
  usePercentRangeResource,
  useTurnoverRateResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import dayAPI from '~/utils/dayAPI'
import { staticStore } from '../heineken_template/_private/staticStore'
import { store } from '../heineken_template/_private/store'
import { Select } from '@mantine/core'
import { css } from '@emotion/react'
import dayjs from 'dayjs'

export const wu5868_store = proxy({
  tradeDate: '' as string,
})

export const Wu5868_SidePane2 = memo<ReactProps>(function Wu5868_SidePane2(props) {
  //盤中: 09:00 ~ 15:00 => lastTradeDate
  //收盤後: 15:00 ~ 05:00 => beforeLastTradeDate
  const tradeDateArray = useSnapshot(staticStore).tradedDate.days
  const day0 = useSnapshot(staticStore).tradedDate.day0

  const tradeDate = useSnapshot(wu5868_store).tradeDate
  const fromDate = dayAPI(tradeDate)
  const endDate = dayAPI(fromDate).set('hour', dayjs().hour())

  //test --
  const fromDateOld = useSnapshot(staticStore).tradedDate.intraday
  const currentDate = dayAPI()

  console.log({
    fromDate: fromDateOld.format('YYYY-MM-DD HH:mm'),
    currentDate: currentDate.format('YYYY-MM-DD HH:mm'),
    fromDateNew: fromDate.format('YYYY-MM-DD HH:mm'),
    currentDateNew: endDate.format('YYYY-MM-DD HH:mm'),
  })

  useEffect(() => {
    wu5868_store.tradeDate = day0.format('YYYY-MM-DD')
  }, [day0])

  const newHighSymbol = apirc.signalWall.data
    .useAsync({
      signal_id: SignalWallId.展哥突破5日高,
      from: fromDate,
      to: endDate,
    })
    .data?.map(f => f.symbol)

  const lowBuySymbol = apirc.signalWall.data
    .useAsync({
      signal_id: SignalWallId.展哥拉回買進,
      from: fromDate,
      to: endDate,
    })
    .data?.map(f => f.symbol)

  const newHighSymbol_ = newHighSymbol?.filter(
    (symbol, index) => newHighSymbol.indexOf(symbol) === index,
  )

  const lowBuySymbol_ = lowBuySymbol?.filter(
    (symbol, index) => lowBuySymbol.indexOf(symbol) === index,
  )

  return (
    <styleds.container>
      <div
        css={css`
          ${fill_horizontal_all_center};
          height: 30px;
        `}
      >
        選股日期
        <Select
          rightSection={<div></div>}
          defaultValue={tradeDateArray.map(s => s.format('YYYY-MM-DD'))[0]}
          data={tradeDateArray.map(s => s.format('YYYY-MM-DD'))}
          onChange={(date: string) => {
            wu5868_store.tradeDate = date
          }}
        />
      </div>
      <styleds.area>
        <styleds.title>創五日新高</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart
            data={newHighSymbol_ ?? []}
            chart={store.charting}
          />
        </styleds.list>
      </styleds.area>
      <styleds.area>
        <styleds.title>拉回買進</styleds.title>
        <styleds.list>
          <SimpleQuoteListInteractWithChart
            data={lowBuySymbol_ ?? []}
            chart={store.charting}
          />
        </styleds.list>
      </styleds.area>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    padding: 8px;
    gap: 2px;
    border-right: 1px solid #aaaaaa;
  `,
  area: styled.div`
    ${fill_vertical_all_center};
    height: 50%;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 32px;
    background-color: #cecece;
    border-radius: 4px;
  `,
  list: styled.div`
    ${fill_vertical_all_center};
    height: calc(100% - 32px);
  `,
}
