import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { store } from '~/pages/heineken_template/_private/store'
import { fontWeight400, fontWeight600 } from '~/css/font'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import QuoteValue from '~/modules/page_modules/cons_modules/SymbolQuote/QuoteValue'

export const AsiajyeSymbolQuote = memo<ReactProps<{ symbol: string }>>(function AsiajyeSymbolQuote(
  props,
) {
  const charting = useSnapshot(store).charting

  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [JSON.stringify(charting.symbol)])

  const value = useSnapshot(signalrStore2.values.quote)[props.symbol]
  const quoteChanges = processQuoteToReadable(value as Signalr.ValueOfOHLC)
  const changeSymbol = quoteChanges.closeChange > 0 ? '+' : null

  return (
    <styleds.Container
      onClick={() => {
        store.charting.changeSymbol(props.symbol)
      }}
    >
      <styleds.ItemName>
        <QuoteValue.Arrow symbolNumber={props.symbol} />
        {props.symbol}
        <SymbolName symbol={props.symbol} />
      </styleds.ItemName>
      <styleds.QuoteItemValue change={quoteChanges.closeChange}>
        <span>{value?.close}</span>
        <styleds.ItemValueChange>
          {changeSymbol}
          {quoteChanges.closeChange}
        </styleds.ItemValueChange>
        <styleds.ItemValueChange>
          {changeSymbol}
          {quoteChanges.closeChangePercent}%
        </styleds.ItemValueChange>
      </styleds.QuoteItemValue>
      <styleds.BidAskContent>
        <styleds.BidValue>{value?.bid === 0 ? '-' : value?.bid}</styleds.BidValue>
        <styleds.AskValue>{value?.ask === 0 ? '-' : value?.ask}</styleds.AskValue>
      </styleds.BidAskContent>
    </styleds.Container>
  )
})

const styleds = {
  Container: styled.div`
    ${pureGrid};
    width: 100%;
    height: 96px;
    justify-items: center;
    flex-shrink: 0;
    grid-template-rows: 28% 44% 28%;
    font-size: 14px;
    background-color: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
  `,
  ItemName: styled.div`
    ${fill_horizontal_all_center}
    background-color: #f0f0f0;
    border-radius: 4px;
    width: 100%;
    ${fontWeight400};
  `,
  QuoteItemValue: styled.div<{ change: number }>`
    ${fill_horizontal_all_center};
    ${pureGrid};
    ${fontWeight400};
    grid-template-columns: 48% 26% 26%;
    color: ${props => (props.change > 0 ? '#ff3333' : props.change < 0 ? '#33aa33' : '#fafafa')};
    font-size: 16px;
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
  ItemValueChange: styled.div`
    ${fill_horizontal_cross_center};
    justify-content: end;
    font-size: 13px;
  `,
  BidAskContent: styled.div`
    ${fill_horizontal_all_center};
    gap: 8px;
  `,
  BidValue: styled.div`
    ${fill_horizontal_all_center};
    background-color: #aa3333;
    color: #fff;
    border-radius: 2px;
  `,
  AskValue: styled.div`
    ${fill_horizontal_all_center};
    background-color: #33aa33;
    color: #fff;
    border-radius: 2px;
  `,
}
