import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import GaugeChart from 'react-gauge-chart'

import { flex, jc } from '~/modules/AppLayout/FlexGridCss'

export const SgxGauges = memo<ReactProps<{ currentValue: number }>>(function Gauges(props) {
  return (
    <styleds.Container>
      <styleds.GaugesMark>
        <styleds.MarkItemTop
          width={100}
          margin={0}
        >
          <div>震盪</div>
        </styleds.MarkItemTop>
        <styleds.MarkItem
          width={60}
          margin={-18}
        >
          <div>偏空</div>
          <div>偏多</div>
        </styleds.MarkItem>
        <styleds.MarkItem
          width={88}
          margin={0}
        >
          <div>強空</div>
          <div>強多</div>
        </styleds.MarkItem>
        <styleds.MarkItem
          width={100}
          margin={8}
        >
          <div>過強</div>
          <div>過弱</div>
        </styleds.MarkItem>
      </styleds.GaugesMark>
      <div
        css={css`
          ${flex.h.allCenter};
          position: absolute;
          margin-top: 22px;
          width: 100%;
        `}
      >
        <GaugeChart
          id={'chart'}
          percent={props.currentValue / 100}
          formatTextValue={() => ''}
          nrOfLevels={100}
          textColor='transparent'
          arcPadding={0.01}
          cornerRadius={0}
          arcWidth={0.08}
          needleBaseColor='#252525'
          needleColor='#252525'
          colors={['#54a843', '#8fd673', '#bce1ad', '#e9e9df', '#f7d1d0', '#e5a2a0', '#e45751']}
          arcsLength={[0.075, 0.075, 0.075, 0.075, 0.075, 0.075, 0.075]}
        />
      </div>
    </styleds.Container>
  )
})

const styleds = {
  Container: styled.div`
    ${flex.v.crossCenter}
    width: 232px;
    height: 124px;
    position: relative;
    font-size: 12px;
  `,
  GaugesMark: styled.div`
    ${flex.v.allCenter};
    width: 100%;
    position: absolute;
    padding: 4px;
    color: #1b1851;
  `,
  MarkItemTop: styled.div<{ margin: number; width: number }>`
    ${flex.h.allCenter};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin};
  `,
  MarkItem: styled.div<{ margin: number; width: number }>`
    ${flex.h.allCenter};
    ${jc.spaceBetween};
    width: ${props => props.width}%;
    height: 28px;
    margin-top: ${props => props.margin}px;
  `,
}
