import styled from '@emotion/styled'
import React, { memo } from 'react'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import { SgxGauges } from './component/SgxGauges'
import { css } from '@emotion/react'
import { useSnapshot } from 'valtio'
import { store } from '../heineken_template/_private/store'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'

export const Sgx_DataCard = memo<ReactProps>(function Sgx_DataCard() {
  const charting = useSnapshot(store.charting)
  return (
    <styleds.container>
      <styleds.title>
        <SymbolName symbol={charting.symbol} /> - 商品訊息
      </styleds.title>
      <styleds.cardCintent>
        <IndicatorsCard />
        <PriceChangeCard />
        <TrendCard />
      </styleds.cardCintent>
    </styleds.container>
  )
})

const PriceChangeCard = memo<ReactProps>(function PriceChangeCard() {
  //-
  const ChangeBar = memo<ReactProps<{ barWidth: number }>>(function ChangeBar(props) {
    const width = props.barWidth
    const barFill = width > 30 ? '#cc3344' : '#33aa44'
    return (
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
        `}
      >
        <div
          css={css`
            margin-left: 16px;
            width: 20%;
          `}
        >
          {props.children}
        </div>
        <div
          css={css`
            width: ${width}%;
            height: 16px;
            background-color: ${barFill};
          `}
        ></div>
        <div
          css={css`
            padding: 0 8px;
          `}
        >
          ({width}%)
        </div>
      </div>
    )
  })

  return (
    <styleds.card height={280}>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 400;
        `}
      >
        近期價格波動
      </div>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 600;
          color: #112267;
          font-size: 16px;
        `}
      >
        表現
      </div>
      <ChangeBar barWidth={40}>5日</ChangeBar>
      <ChangeBar barWidth={30}>10日</ChangeBar>
      <ChangeBar barWidth={44}>20日</ChangeBar>
      <ChangeBar barWidth={28}>60日</ChangeBar>
      <ChangeBar barWidth={43}>120日</ChangeBar>
    </styleds.card>
  )
})

// 要做5種技術指標 macd-osc、kd、dmi、vix、sar
const IndicatorsCard = memo<ReactProps>(function IndicatorsCard() {
  const trend = '強勢趨勢'
  return (
    <styleds.card height={280}>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 400;
        `}
      >
        技術指標彙整
      </div>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 600;
          color: #cc3344;
          font-size: 16px;
        `}
      >
        {trend}
      </div>
      <SgxGauges currentValue={70} />
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 64px;
          display: grid;
          grid-template-columns: 33% 33% 33%;
          padding: 8px;
          & > * {
            ${fill_vertical_all_center};
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            & > * {
              ${fill_vertical_all_center}
            }
          `}
        >
          <span
            css={css`
              width: 48px;
              height: 24px;
              background-color: #33aa44;
              color: #fff;
              border-radius: 4px;
            `}
          >
            空方
          </span>
          <span>1項</span>
        </div>
        <div
          css={css`
            & > * {
              ${fill_vertical_all_center}
            }
          `}
        >
          <span
            css={css`
              width: 48px;
              height: 24px;
              background-color: #cccccc;
              color: #252525;
              border-radius: 4px;
            `}
          >
            盤整
          </span>
          <span>1項</span>
        </div>
        <div
          css={css`
            & > * {
              ${fill_vertical_all_center}
            }
          `}
        >
          <span
            css={css`
              width: 48px;
              height: 24px;
              background-color: #aa3344;
              color: #fff;
              border-radius: 4px;
            `}
          >
            多方
          </span>
          <span>3項</span>
        </div>
      </div>
    </styleds.card>
  )
})

const TrendCard = memo<ReactProps>(function TrendCard() {
  const IndicatorsSma = memo<ReactProps<{ value: number; length: number }>>(function IndicatorsSma(
    props_,
  ) {
    const trendState = props_.value > 580 ? '多方' : '空方'
    const trendFill = props_.value > 580 ? '#aa3344' : '#33aa44'
    return (
      <div
        css={css`
          ${fill_horizontal_cross_center};
          ${jc.spaceAround};
        `}
      >
        <div>簡單移動平均線({props_.length})</div>
        <div>{props_.value}</div>
        <div
          css={css`
            ${fill_horizontal_all_center};
            width: 40px;
            height: 24px;
            border-radius: 4px;
            color: #ffffff;
            background-color: ${trendFill};
          `}
        >
          {trendState}
        </div>
      </div>
    )
  })
  return (
    <styleds.card height={280}>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 400;
        `}
      >
        商品趨勢
      </div>
      <div
        css={css`
          ${fill_horizontal_cross_center};
          height: 30px;
          font-weight: 600;
          color: #112267;
          font-size: 16px;
        `}
      >
        表現
      </div>
      <IndicatorsSma
        value={587}
        length={5}
      />
      <IndicatorsSma
        value={562}
        length={10}
      />
      <IndicatorsSma
        value={555}
        length={20}
      />
      <IndicatorsSma
        value={589}
        length={60}
      />
      <IndicatorsSma
        value={566}
        length={120}
      />
    </styleds.card>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 8px;
    border-top: 1px solid #e0e4ea;
  `,
  cardCintent: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-gap: 4px;
  `,
  title: styled.div`
    ${fill_horizontal_cross_center};
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    color: #112267;
  `,
  card: styled.div<{ height: number }>`
    ${fill_vertical_cross_center};
    width: 100%;
    height: ${props => props.height}px;
    background-color: #fafafa;
    border: 1px solid #acacac;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    gap: 4px;
  `,
}
