import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { store } from '~/pages/heineken_template/_private/store'
import { fontWeight400 } from '~/css/font'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import QuoteValue from '~/modules/page_modules/cons_modules/SymbolQuote/QuoteValue'

export const SgxSymbolQuote = memo<ReactProps<{ symbol: string; digits: number }>>(
  function SgxSymbolQuote(props) {
    const charting = useSnapshot(store).charting
    const digits = props.digits ?? 2
    useEffect(() => {
      signalrStore2.addQuote(props.symbol)
      return () => {
        signalrStore2.removeQuote(props.symbol)
      }
    }, [JSON.stringify(charting.symbol)])

    const value = useSnapshot(signalrStore2.values.quote)[props.symbol]
    const quoteChanges = processQuoteToReadable(value as Signalr.ValueOfOHLC)
    const changeSymbol = quoteChanges.closeChange > 0 ? '+' : null

    return (
      <styleds.Container>
        <styleds.ItemName>
          {props.symbol}
          <SymbolName symbol={props.symbol} />
        </styleds.ItemName>
        <styleds.QuoteItemValue change={0}>
          <QuoteValue.Arrow symbolNumber={props.symbol} />
          {value?.close?.toFixed(digits)}
        </styleds.QuoteItemValue>
        <styleds.QuoteItemValue change={quoteChanges.closeChange}>
          <styleds.ItemValueChange>
            {changeSymbol}
            {quoteChanges.closeChange?.toFixed(digits)}
          </styleds.ItemValueChange>
          <styleds.ItemValueChange>
            {changeSymbol}
            {quoteChanges.closeChangePercent?.toFixed(digits)}%
          </styleds.ItemValueChange>
        </styleds.QuoteItemValue>
        {/* <styleds.BidValue>{value?.bid === 0 ? '-' : value?.bid}</styleds.BidValue>
        <styleds.AskValue>{value?.ask === 0 ? '-' : value?.ask}</styleds.AskValue> */}
      </styleds.Container>
    )
  },
)

const styleds = {
  Container: styled.div`
    ${pureGrid};
    grid-template-columns: 30% 30% 40%;
    width: 100%;
    height: 80px;
    justify-items: center;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 600;
    background-color: #f7f7f7;
    border-radius: 2px;
    cursor: pointer;
    padding: 4px;
    &:hover {
      transition: 0.3s;
    }
  `,
  ItemName: styled.div`
    ${fill_horizontal_all_center};
    width: 100%;
    border-radius: 4px;
    color: #222222;
    font-weight: 600;
  `,
  QuoteItemValue: styled.div<{ change: number }>`
    ${fill_horizontal_all_center};
    color: ${props => (props.change > 0 ? '#af2453' : props.change < 0 ? '#81ab33' : '#252525')};
    font-size: 14px;
  `,
  ItemValueChange: styled.div`
    ${fill_horizontal_all_center};
  `,
  BidAskContent: styled.div`
    ${fill_horizontal_all_center};
    gap: 8px;
  `,
  BidValue: styled.div`
    ${fill_horizontal_all_center};
    background-color: #af2453;
    border-radius: 2px;
  `,
  AskValue: styled.div`
    ${fill_horizontal_all_center};
    background-color: #81ab33;
    border-radius: 2px;
  `,
}
