import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { SgxSymbolQuote } from './component/SymbolQuote'

export const Sgx_SidePane1 = memo<ReactProps>(function Sgx_SidePane1() {
  return (
    <styleds.container>
      <SgxSymbolQuote
        symbol='TWN-1'
        digits={2}
      />
      <SgxSymbolQuote
        symbol='MTW-1'
        digits={2}
      />
      <SgxSymbolQuote
        symbol='CN-1'
        digits={2}
      />
      <SgxSymbolQuote
        symbol='FCH-1'
        digits={2}
      />

      <SgxSymbolQuote
        symbol='NK-1'
        digits={2}
      />
    </styleds.container>
  )
})

export const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    gap: 4px;
    border-right: 1px solid #e0e4ea;
  `,
}
