import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_horizontal_cross_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

// "symbol": "TWNM23",
// "date": "2023-06-01",
// "last_trade_date": "2023-06-28",
// "first_trade_date": "2020-07-20",
// "open_interest": 146989,
// "trading_session": 0,
// "open": 1416.75,
// "high": 1423.75,
// "low": 1415,
// "close": 1418.75,
// "change": -1.5,
// "total_volume": 34606,
// "off_exchange_volume": 0,
// "volume": 34606,
// "delivery_month": "2023-06",
// "last_close": 1418.75

export const Sgx_SidePane2 = memo<ReactProps>(function Sgx_SidePane2() {
  return (
    <styleds.container>
      <styleds.symbolInfoCard>
        <styleds.infoValue>
          <span>開盤</span>
          <span>1416.75</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>最高</span>
          <span>1416.75</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>最低</span>
          <span>1416.75</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>收盤</span>
          <span>1416.75</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>前次收盤</span>
          <span>1416.75</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>交易量</span>
          <span>34606</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>價格變動</span>
          <span>-1.5</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>價格變動%</span>
          <span>-1.5%</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>未平倉量</span>
          <span>146989 口</span>
        </styleds.infoValue>
        <styleds.infoValue>
          <span>振幅</span>
          <span>3.2</span>
        </styleds.infoValue>
      </styleds.symbolInfoCard>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 8px;
    border-left: 1px solid #e0e4ea;
  `,
  symbolInfoCard: styled.div`
    position: sticky;
    ${fill_vertical_cross_center};
    width: 100%;
    height: 400px;
    background-color: #f7f7f7;
    border: 1px solid #acacac;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    gap: 4px;
  `,
  infoValue: styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  `,
}
