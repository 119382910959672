import styled from '@emotion/styled'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useExtremeVolumeResource } from '~/modules/screener/containers/useStockScreenerResource'
import { SymbolPlatformChangeFill } from '~/modules/symbolPlatform/changFill/SymbolPlatformChangeFill'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { staticStore } from '../heineken_template/_private/staticStore'
import { apirc } from '~/configs/apirc'

export const Revive_SidePane1 = memo<ReactProps>(function Revive_SidePane1() {
  const origin = apirc.stock.screenerAPI.dailyRank.useSWR(
    {
      order_by: 'desc',
      sort_by: 'change_percent',
      amount_gte: 10e8,
      amount_lte: 10e12,
      volume_gte: 10000,
      volume_lte: 99999,
    },
    {
      refreshInterval: 10 * 1000,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  )

  const data = origin?.data?.map(s => s.symbol)
  return (
    <styleds.container>
      <styleds.card height={'calc(100% - 0px)'}>
        <SymbolPlatformChangeFill
          symbol={data ?? []}
          watchListGroup1='revive_group_1'
          watchListGroup2='revive_group_2'
        />
      </styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 2px;
  `,
  card: styled.div<{ height: number | string }>`
    ${fill_vertical_all_center};
    height: ${props => props.height};
    background-color: #25262a;
    border-radius: 8px;
  `,
}
