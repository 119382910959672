import { memo } from 'react'
import styled from '@emotion/styled'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { AsiajyeSymbolQuote } from './component/AsiajyeSymbolQuote'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { css } from '@emotion/react'

export const Asiajye_intl_SidePane = memo<ReactProps>(function Asiajye_intl_SidePane() {
  return (
    <styleds.container>
      <div
        css={css`
          ${fill_vertical_cross_center};
          ${scrollbar2Css};
          height: calc(100vh - 48px);
          padding: 8px;
          gap: 8px;
        `}
      >
        <AsiajyeSymbolQuote symbol='TWN-1' />
        <AsiajyeSymbolQuote symbol='YM-1' />
        <AsiajyeSymbolQuote symbol='NQ-1' />
        <AsiajyeSymbolQuote symbol='ES-1' />
        <AsiajyeSymbolQuote symbol='EC-1' />
        <AsiajyeSymbolQuote symbol='GC-1' />
        <AsiajyeSymbolQuote symbol='NK-1' />
        <AsiajyeSymbolQuote symbol='CN-1' />
        <AsiajyeSymbolQuote symbol='HSI-1' />
      </div>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
  `,
}
