import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const sgx_TopInfo = memo<ReactProps>(function sgx_TopInfo() {
  return <styleds.container></styleds.container>
})

const styleds = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    border: 1px solid #e0e3ea;
    background-image: url('sgx/derivatives.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  `,
}
