import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const daddy960_revive_tdcc = createIndicator({
  displayName: '大戶比例',
  id: 'daddy960-revive-tdcc',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(context)
        .toString()
        .replace(/[^\w-]/g, '')

      const symbol = ticker + '#tdcc_stock_ratio|big_level@14'

      context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)

      const tdcc = this.PineJS.Std.high(this._context)

      const tdcc_array = this._context.new_var(tdcc)
      tdcc_array.get(1)

      if (tdcc !== tdcc_array.get(0)) {
        tdcc_array.set(tdcc)
      }

      const colorIndex = this._context.new_var()

      if (tdcc > tdcc_array.get(1)) {
        colorIndex.set(0)
      }
      if (tdcc < tdcc_array.get(1)) {
        colorIndex.set(1)
      }

      return [tdcc, colorIndex.get(0)]
    },
  },
  metainfo: {
    precision: 2,
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },

      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },
    ],
    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '市場多方能量',
          },
          1: {
            name: '市場空方能量',
          },
        },
      },
    },
    defaults: {
      styles: {
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#ffffff',
        },
      },
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#dd5441',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00aa0a',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },
    styles: {
      vol: {
        title: '能量',
        histogramBase: 0,
        joinPoints: !1,
      },
    },
    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})

// tdcc_stock_ratio: {
//   name: "集保戶股權分散表(持股比例)",
//   description: "集保戶股權分散表(持股比例)",
//   type: "futures",
//   endpoint: "tdcc_stock",
//   open: "small_ratio",
//   high: "big_ratio",
//   low: "small_people",
//   close: "big_people",
//   volume: "total_people",
// },
// tdcc_stock_volume: {
//   name: "集保戶股權分散表(持股張數)",
//   description: "集保戶股權分散表(持股張數)",
//   type: "futures",
//   endpoint: "tdcc_stock",
//   open: "small_volume",
//   high: "big_volume",
//   low: "small_people",
//   close: "big_people",
//   volume: "total_volume",
// }

// level: 持有股數
// '1': (1, 999)
// '2': (1000, 5000)
// '3': (5001, 10000)
// '4': (10001, 15000)
// '5': (15001, 20000)
// '6': (20001, 30000)
// '7': (30001, 40000)
// '8': (40001, 50000)
// '9': (50001, 100000)
// '10': (100001, 200000)
// '11': (200001, 400000)
// '12': (400001, 600000)
// '13': (600001, 800000)
// '14': (800001, 1000000)
// '15': (1000001, 999999999)
